/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Kit 2 React base styles
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import typography from "assets/theme/base/typography";

// Material Kit 2 React helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const { light, text, dark, success } = colors;
const { borderRadius } = borders;
const { size } = typography;

export default {
  styleOverrides: {
    root: {
      // minWidth: pxToRem(160),
      // minHeight: "unset",
      // padding: `${pxToRem(4.8)} ${pxToRem(16)}`,
            padding: `${pxToRem(2)} ${pxToRem(8)}`, // Changed by Andrey to get more space
      // borderRadius: borderRadius.md,
      // fontSize: size.sm,
      // color: text.main,
      // transition: "background-color 200ms ease, color 200ms ease",

      // "&:hover, &:focus, &.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus": {
        // backgroundColor: light.main,
        // color: dark.main,
      // },
    },
  },
};
