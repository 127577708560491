import { Worker, Viewer, pageLayout } from '@react-pdf-viewer/core';
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { highlightPlugin, Trigger, MessageIcon } from '@react-pdf-viewer/highlight';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { Plugin, PluginFunctions, createStore, SpecialZoomLevel } from '@react-pdf-viewer/core';

import React, { useState, useRef, useMemo } from 'react';
//import type { HighlightArea, RenderHighlightsProps } from '@react-pdf-viewer/highlight';
// import customZoomPlugin from '../components/customZoomPlugin/customZoomPlugin';
import { HighlightArea, RenderHighlightsProps } from '@react-pdf-viewer/highlight';
import { testHighlights as _testHighlights, test_file } from "../test-highlights";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { MathJax, MathJaxContext } from "better-react-mathjax";
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/highlight/lib/styles/index.css';
import Grid from "@mui/material/Grid";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import { IoMdClose } from "react-icons/io";
import { FaLink } from "react-icons/fa";
import Card from "@mui/material/Card";
import MKTypography from "components/MKTypography";
import Typography from '@mui/material/Typography';
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import { MdOutlineNearbyError } from "react-icons/md";
import Icon from "@mui/material/Icon";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import Stack from "@mui/material/Stack";
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import { SiPowerpages } from "react-icons/si";
import { MdTableChart } from "react-icons/md";
import FeedbackFab from 'components/ZencheckComponents/FeedbackFab';


/*interface RenderHighlightAreasExampleProps {
    areas: HighlightArea[];
    firstPdf: string;
    secondPdf: string;
}*/


let formated_highlights/*: Array<HighlightArea>*/ = [];
let localCurrentGeneralMargin = 0;
let localCurrentPercentageMargin = 0;
const FactualAccuracy/*: React.FC<RenderHighlightAreasExampleProps>*/ = ({ areas, firstPdf, secondPdf, currentGeneralMargin, currentPercentageMargin, docName }) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar: (Toolbar) => (
      <Toolbar>
        {(slots) => {
          const {
            CurrentPageInput,
            Download,
            GoToNextPage,
            GoToPreviousPage,
            NumberOfPages,
            Print,
            ShowSearchPopover,
            SwitchTheme,
            Zoom,
            ZoomIn,
            ZoomOut,
          } = slots;
          return (
            <div className='toolbar' >
              <div className='toolbar-left' >
                <div style={{ padding: '0px 2px' }}><ShowSearchPopover /></div>
                <div style={{ padding: '0px 2px' }}><ZoomOut /></div>
                <div style={{ padding: '0px 2px' }}><Zoom /></div>
                <div style={{ padding: '0px 2px' }}><ZoomIn /></div>
              </div>
              <div className='toolbar-center' >
                <div style={{ padding: '0px 2px' }}><GoToPreviousPage /></div>
                <div style={{ padding: '0px 2px', marginRight: "12px", display: "flex", alignItems: "center", fontSize: "16px", background: "transparent", color: "#667085", minWidth: "fit-content" }}>
                  <CurrentPageInput style={{ width: "2.2REM", background: "transparent" }} /> of <NumberOfPages />
                </div>
                <div style={{ padding: '0px 2px' }}><GoToNextPage /></div>
              </div>
              <div className='toolbar-right' >
                <div className='embeded-doc-title'>
                  {/*docName*/}
                  </div>
              </div>
            </div>
          );
        }}
      </Toolbar>
    ),
    sidebarTabs: (defaultTabs) => {
      const allTabs = defaultTabs
      return [
        allTabs[0],
      ]
    }
  },
  );

  // Add 12px margin between pages
const pageLayout = {
  buildPageStyles: ({ numPages, pageIndex, scrollMode, viewMode }) => ({
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
  }),
  transformSize: ({ numPages, pageIndex, size }) => ({
      height: size.height + 12,
      width: size.width + 12,
  }),
};

  const defaultLayoutPluginInstance2 = defaultLayoutPlugin();
  const [highlightedItem, sethighlightedItem] = useState(-1);
  const [highlightedItems, sethighlightedItems] = useState({});
  const [mainHighlightedItemType, setMainHighlightedItemType] = useState("Total");
  const [mainHighlightedItem, setMainHighlightedItem] = useState(null);
  const [selectedUploaadId, setSelectedUploaadId] = useState("");
  //const [localCurrentGeneralMargin, setLocalCurrentGeneralMargin] = useState(currentGeneralMargin);
  //const [localCurrentPercentageMargin, setLocalCurrentPercentageMargin] = useState(currentPercentageMargin);

  const [selectedItem, setSelectedItem] = useState({
    x: 0,
    y: 0,
    title: "title",
    value: 1,
    positionX: 0,
    positionY: 0,
    pageIndex: -1,
    references: [{
      id: 0,
      page: 4,
      value: 11
    }]
  });

  const currencySymbols = {
    "$": 1,
    "€": 1,
    "£": 1,
    "¥": 1,
    "₹": 1,
    "₽": 1,
    "₩": 1,
    "₿": 1

  }


  // const defaultLayoutPluginInstance = defaultLayoutPlugin({
  //   renderToolbar: (Toolbar) => (
  //     <Toolbar>
  //       {(slots) => {
  //         const {
  //           CurrentPageInput,
  //           Download,
  //           GoToNextPage,
  //           GoToPreviousPage,
  //           NumberOfPages,
  //           Print,
  //           ShowSearchPopover,
  //           SwitchTheme,
  //           Zoom,
  //           ZoomIn,
  //           ZoomOut,
  //         } = slots;
  //         return (
  //           <div
  //             style={{
  //               padding: '4px',
  //               alignItems: 'center',
  //               display: 'flex',
  //               width: '100%',
  //             }}
  //           >
  //             <div style={{ padding: '0px 2px' }}><ShowSearchPopover /></div>
  //             <div style={{ padding: '0px 2px' }}><ZoomOut /></div>
  //             <div style={{ padding: '0px 2px' }}><Zoom /></div>
  //             <div style={{ padding: '0px 2px' }}><ZoomIn /></div>
  //             <div style={{ padding: '0px 2px', marginLeft: 'auto' }}><GoToPreviousPage /></div>
  //             <div style={{ padding: '0px 2px', marginRight: "12px", display: "flex", alignItems: "center", fontSize: "16px", background: "transparent", color: "#667085" }}>
  //               <CurrentPageInput style={{ width: "2.2REM", background: "transparent" }} /> of <NumberOfPages />
  //             </div>
  //             <div style={{ padding: '0px 2px' }}><GoToNextPage /></div>
  //             {/* <div style={{ padding: '0px 2px' }}><SwitchTheme /></div> */}
  //             <div style={{ padding: '8px', fontSize: "16px", fontWeight: 'medium', marginLeft: 'auto' }}>PDF_name.pdf</div>
  //           </div>
  //         );
  //       }}
  //     </Toolbar>
  //   ),
  //   sidebarTabs: (defaultTabs) => {
  //     const allTabs = defaultTabs.concat({
  //     })
  //     return [
  //       allTabs[0],
  //     ]
  //   }
  // },
  // );


  const extractMathOperators = (mathString) => {
    // This regex matches the common mathematical operators
    const regex = /[\+\-\*\/\^]/g;
    const operators = mathString.match(regex);
    return operators ? operators : [];
  }


  const extractFinancialNumbers = (text) => {
    // Regex explanation:
    // - Match optional $ symbol: \$?
    // - Match optional negative sign: -?
    // - Match digits possibly separated by commas: (\d+(?:,\d+)*)+
    // - Match optional decimal part: (\.\d+)?
    // - Ensure it's followed by optional space, financial denominators (k, M, B) or percentage sign (%), case-insensitive
    // - Ensure it's followed by either a space, the end of the string, or non-letter character (excluding % for percentages) to avoid partial matches
    const regex = /-?\$?\(?-?(\d+(?:,\d+)*)+(\.\d+)?\)?\s?(k|M|B|%)?(?=(\s|$|\W[^%]))/gi;
  
    const matches = text.match(regex) || [];
    return matches.map(match => {
      return match;
    });
}

const formatFinalResult = (result, originalNumberString) => {
  if (originalNumberString && Number(cleanNumber(result)) < 0 && originalNumberString.indexOf("(") === 0) {
    return `(${Math.abs(Number(cleanNumber(result))).toLocaleString()})`
  } else {
    return result.toLocaleString()
  }
}

const hasRecurringNines = (numberString) => {
  // Regular expression to detect recurring digits
  const recurringPattern = /(\d)\1{5,}/;
  return recurringPattern.test(numberString);
}

const removeSymbolsFromNumbers = (inputString) => {
  return inputString.replace(/[^0-9.-]/g, "");
}

const handleCommasInNumbers = (inputStr) => {
  if (inputStr.indexOf(".") === -1) {
    if (inputStr.indexOf(",") !== -1 && inputStr.split(",")[1].length < 3) {
      return inputStr.replace(",",".");
    }
  } else if (inputStr.indexOf(".") !== -1 && inputStr.split(".")[1].length >= 3 && !hasRecurringNines(inputStr)) {
    return inputStr.replace(".","")
  }
  return inputStr;
}


const cleanNumber = (input) => {
  // Early return for null or undefined inputs
  if (input === null || input === undefined) {
      return ''; // Return a default value or handle as needed
  }

  let inputStr = input.toString();
  inputStr = handleCommasInNumbers(inputStr);
  if (inputStr === "-") inputStr = inputStr.replace("-","0");
  


  // Separate the input into numeric and suffix parts
  let numericPart = inputStr.replace(/[^\d.%()-]/g, '');
  const suffixPart = inputStr.match(/(pp|x)$/);

  // Process for negative values enclosed in brackets
  if (numericPart.startsWith('(') && numericPart.endsWith(')')) {
      numericPart = '-' + numericPart.slice(1, -1); // Remove brackets and prepend with '-' sign
  }

  // Handle cases with negative percentage
  if (numericPart.endsWith('%')) {
      if (numericPart.startsWith('(') && numericPart.endsWith(')%')) {
          numericPart = '-' + numericPart.slice(1, -2) + '%'; // Convert to negative percentage
      }
  }

  // Append suffix if it exists
  if (suffixPart) {
      numericPart += suffixPart[0]; // Append "pp" or "x" if present
  }

  return numericPart;
}



  let lastRange = { y: 0, height: 0 };
  let rowIndex = -1;
  let actualCurrentRow = -1;
  if (selectedUploaadId !== localStorage.getItem('uploadId') || localCurrentGeneralMargin !== currentGeneralMargin || localCurrentPercentageMargin !== currentPercentageMargin) {
    formated_highlights = [];
    // if (localCurrentGeneralMargin !== currentGeneralMargin) setLocalCurrentGeneralMargin(localCurrentGeneralMargin);
    //if (localCurrentPercentageMargin !== currentPercentageMargin) setLocalCurrentPercentageMargin(localCurrentPercentageMargin);
    localCurrentGeneralMargin = currentGeneralMargin;
    localCurrentPercentageMargin = currentPercentageMargin;

    setSelectedUploaadId(localStorage.getItem('uploadId'));
  }
  //console.log("test_file: ",test_file)
  //console.log("areas: ",areas)
  if (formated_highlights.length === 0)
    areas.filter(a => a && ((a.isHeaderItem && a.isHeaderItemConfidence > 0.95) || !a.isHeaderItem || a.isHeaderItemConfidence < 0.95))/*.filter(a => a.text === "__FY2023")*/.forEach((item, index) => {
      if (item.top > (lastRange.y + lastRange.height)) {
        rowIndex = 0;
        actualCurrentRow++;
        lastRange.y = item.top;
        lastRange.height = item.height;
      } else {
        rowIndex++;
      }

      const adjustWidth = item.width * 2; //200;

      const percentageSplit = item.left / 1600;
      const percentageSplitWidth = item.width / 1600;
      const actualX = (617 * 1.16) * percentageSplit;
      const actualWidth = (617 * 1.16) * percentageSplitWidth;
      const percentageSplitY = item.top / 1600;
      const actualY = percentageSplitY * (476 * 2.21);
      let calculationType = "";
      let calculationOperators = [];

      const references = [];

      if (item.text === undefined) {
        item.text = "";
      }

      if (item.originalText === undefined) {
        item.originalText = "";
      }

      if (item.references) {
        item.references.forEach(element => {
          references.push(areas[element.id])
        });
      }

      if (item.summedItem) {
        let checkText = item.text.replace("%", "");
        

        if (checkText.indexOf("+") === 0) checkText = item.text.replace("+", "");
        item.summedItem.itemsSummed = item.summedItem.itemsSummed ? item.summedItem.itemsSummed /*.filter(sumItem => !isNaN(Number(cleanNumber(checkText))))*/ : [];
        console.log("seting in C")

        calculationOperators = item.summedItem ? item.summedItem.itemsSummed.map(() => "") : [""];
      }

      let isWrong = true;
      let calculatedNumber = null;
      const oldItemText = item.text;

      const extracted = extractFinancialNumbers(item.text.replace("%", "").replace("$", ""));
      item.text = cleanNumber(extracted.length ? extracted[0] : item.text);

      if (item.text.indexOf("+") === 0) item.text = item.text.replace("+", "");

      if (isWrong && item.averagedItem && item.tableColumn.toLowerCase().indexOf("average") !== -1 && item.tableColumn.toLowerCase().indexOf("volume") !== -1 && (item.tableColumn.toLowerCase().indexOf("p.a") !== -1 || item.tableColumn.toLowerCase().indexOf("annual") !== -1)) {
        let numOfItems = item.averagedItem.itemsAveraged.filter(summedItem => !isNaN(Number(summedItem.text.replace("%", "").indexOf("+"))));
        calculationOperators = item.averagedItem.itemsAveraged.map(() => "");
        calculationType = "Average";

        //delete this
        /*if (numOfItems.length === 2) {
          const V_final =  Number(numOfItems[1].text);
        const V_initial = Number(numOfItems[0].text);
        const n = 2022 - 2017; //extract numbers from header
        
        const CAGR = ((V_final / V_initial) ** (1 / n)) - 1;
        const CAGR_percentage = CAGR * 100;
        console.log({CAGR_percentage});
        item.averagedItem.cagr = CAGR_percentage;
        item.averagedItem.numOfItems = numOfItems;
        calculatedNumber = item.averagedItem.cagr;
        console.log("calculatedNumber: ",calculatedNumber)
        if (Math.abs(Number(item.averagedItem.cagr) - Number(item.text.replace("%",""))) <= currentPercentageMargin) isWrong = false;
        
        }*/


      }
      if (isWrong && item.summedItem && (item.summedItem.isPercentage /*|| (item.tableColumn && item.tableColumn.indexOf("%") !== -1)*/)) {
        let numOfItems = item.summedItem.itemsSummed.filter(summedItem => !isNaN(Number(summedItem.text)));
        calculationOperators = item.summedItem.itemsSummed.map(() => "");
        console.log({ numOfItems })

        //Not used anymore for the most part
        if (numOfItems.length === 2) {
          calculationType = "% growth";
          item.summedItem.numOfItems = numOfItems;
          item.summedItem.toalPercentageIncrease = ((Number(numOfItems[1].text) - Number(numOfItems[0].text)) / Number(numOfItems[0].text)) * 100;
          console.log({ hey: item.summedItem.toalPercentageIncrease, summedItem: item, abs: Math.abs(Number(item.summedItem.toalPercentageIncrease) - Number(item.text.replace("%", ""))) })
          if (Math.abs(Number(item.summedItem.toalPercentageIncrease) - Number(item.text.replace("%", ""))) <= currentPercentageMargin) isWrong = false;
          calculatedNumber = item.summedItem.toalPercentageIncrease;

        }

      } else if (isWrong && item.summedItem) {
        console.log("SUMMED: ", oldItemText)
        //calculationType = "Total";
        calculatedNumber = item.summedItem.summedTotal;
        if (Math.abs(Number(item.summedItem.summedTotal) - Number(removeSymbolsFromNumbers(cleanNumber(item.originalText)))) <= (currentGeneralMargin + 0.01)) isWrong = false;
        console.log({ItemText: item.text, extracted, isWrong, newText: item.text, summedTotal: item.summedItem.summedTotal, itemx: JSON.parse(JSON.stringify(item)), item, calc: Math.abs(Number(item.summedItem.summedTotal) - Number(item.text)), currentGeneralMargin})
      } else if (isWrong && item.averagedItem) {
        calculationType = "Average";
        calculatedNumber = item.averagedItem.averaged;
        if (Math.abs(Number(item.averagedItem.averaged) - Number(removeSymbolsFromNumbers(cleanNumber(item.originalText)))) <= (currentGeneralMargin + 0.01)) isWrong = false;
      }

      if (/*isWrong &&*/ (item.rowCalc || item.colCalc)) {
        if (item.rowCalcResult !== undefined) {
          
          if (item.originalText.indexOf("%") !== -1) {
            if (Math.abs(Number(item.rowCalcResult) - Number(removeSymbolsFromNumbers(cleanNumber(item.originalText)))) <= (currentPercentageMargin + 0.01)) isWrong = false;
          } else {
            if (Math.abs(Number(item.rowCalcResult) - Number(removeSymbolsFromNumbers(cleanNumber(item.originalText)))) <= (currentGeneralMargin + 0.01)) isWrong = false;
          }
          calculationType = item.rowCalculationType;
          calculatedNumber = item.rowCalcResult;
          const rowCalcOperators = extractMathOperators(item.rowCalc)
          if (calculationType === "Total") {
            console.log({item})
            calculationOperators = rowCalcOperators ? rowCalcOperators.map(() => "") : [""] //rowCalcOperatorsextractMathOperators(item.rowCalc).map(() => "");
          } else {
            calculationOperators = [""];
            if (rowCalcOperators)
            calculationOperators = calculationOperators.concat(rowCalcOperators);
          }

          
          if (item.summedItem) {
            console.log("seting in D")
            item.summedItem.itemsSummed = item.rowCalcItems;
            item.summedItem.summedTotal = item.rowCalcResult;
            calculatedNumber = item.rowCalcResult;
            console.log("setting items using this: ",item.summedItem.itemsSummed)
          } else if (item.averagedItem) {
            item.averagedItem.itemsAveraged = item.rowCalcItems;
            item.averagedItem.averaged = item.rowCalcResult;
            calculatedNumber = item.rowCalcResult;
          } else {
            item.summedItem = {
              itemsSummed: item.rowCalcItems,
              summedTotal: item.rowCalcResult
            }
            calculatedNumber = item.rowCalcResult;
          }
        }

        if (item.colCalcResult !== undefined) {
          console.log("aaa")
          if (item.originalText.indexOf("%") !== -1) {
            if (Math.abs(Number(item.colCalcResult) - Number(removeSymbolsFromNumbers(cleanNumber(item.originalText)))) <= (currentPercentageMargin + 0.01)) isWrong = false;
          } else {
            if (Math.abs(Number(item.colCalcResult) - Number(removeSymbolsFromNumbers(cleanNumber(item.originalText)))) <= (currentGeneralMargin + 0.01)) isWrong = false;
          }
          
          
          calculationType = item.colCalculationType;
          calculatedNumber = item.colCalcResult;
          if (calculationType === "Total") {
            console.log({item})
            calculationOperators = extractMathOperators(item.colCalc).map(() => "");
          } else {
            calculationOperators = [""];
            calculationOperators = calculationOperators.concat(extractMathOperators(item.colCalc));
          }
          if (item.summedItem) {
            console.log("seting in A")
            item.summedItem.itemsSummed = item.colCalcItems;
            item.summedItem.summedTotal = item.colCalcResult;
            calculatedNumber = item.colCalcResult;
          } else if (item.averagedItem) {
            item.averagedItem.itemsAveraged = item.colCalcItems;
            item.averagedItem.averaged = item.colCalcResult;
            calculatedNumber = item.colCalcResult;
          } else {
            item.summedItem = {
              itemsSummed: item.colCalcItems,
              summedTotal: item.colCalcResult
            }
            calculatedNumber = item.colCalcResult;
          }
        } else if (item.colCalcResult !== undefined) {
          console.log("bbb")
          calculatedNumber = item.colCalcResult;
          if (calculationType === "Total") {
            console.log({item})
            calculationOperators = extractMathOperators(item.colCalc).map(() => "");
          } else {
            calculationOperators = [""];
            calculationOperators = calculationOperators.concat(extractMathOperators(item.colCalc));
          }
          if (item.summedItem) {
            console.log("seting in B")
            item.summedItem.itemsSummed = item.colCalcItems;
            item.summedItem.summedTotal = item.colCalcResult;
          } else if (item.averagedItem) {
            item.averagedItem.itemsAveraged = item.colCalcItems;
            item.averagedItem.averaged = item.colCalcResult;
          } else {
            item.summedItem = {
              itemsSummed: item.colCalcItems,
              summedTotal: item.colCalcResult
            }
          }
        }
      }

      item.text = oldItemText;


      if (item.summedItem && item.summedItem.isDifference) {
        console.log("calculationOperators: ",calculationOperators)
        calculationType = "Difference";
      }

      if (item.summedItem && item.summedItem) {
        item.summedItem.itemsSummed.forEach((element, idx) => {
          if (Number(element.text) < 0 && calculationOperators[idx] === "-") {
            calculationOperators[idx] = "+";
          }
        });
      }

      if (/*isWrong &&*/ item.rowCalcItems) {
        if (!item.summedItem) item.summedItem = {};
        calculationType = item.rowCalculationType;
        calculatedNumber = item.rowCalcResult;
        item.summedItem.itemsSummed = item.rowCalcItems;
        item.summedItem.summedTotal = item.rowCalcResult;
      } else if (/*isWrong && */item.colCalcItems) {
        if (!item.summedItem) item.summedItem = {};
        calculationType = item.colCalculationType;
        calculatedNumber = item.colCalcResult;
        item.summedItem.itemsSummed = item.colCalcItems;
        item.summedItem.summedTotal = item.colCalcResult;
      }

      if (item.summedItem) {
        item.summedItem.itemsSummed.forEach(element => {
          const origitnalItem = areas.find(cell => cell.id === element.id);
          if (origitnalItem) {
            element.originalText = origitnalItem.originalText;
          }
        });
      }

      formated_highlights.push({
        id: item.id,
        text: item.text,
        originalText: item.originalText,
        left: (item.left / 2800) * 100,
        top: (item.top / 2800) * 100,
        width: (item.width / 2800) * 100,
        height: (item.height / 2800) * 100,
        pageIndex: item.page_num - 1,
        title: item.title,
        tableTitle: item.tableTitle,
        calculationType,
        references,
        rowCalcResult: item.rowCalcResult,
        colCalcResult: item.colCalcResult,
        summedItem: item.summedItem,
        averagedItem: item.averagedItem,
        contextB: item.contextB,
        contextC: item.tableRow,
        isWrong,
        calculatedNumber,
        calculationOperators,
        page_num: item.page_num,
        absCheck: item.summedItem ? Math.abs(Number(item.summedItem.summedTotal) - Number(item.text.replace("%", "").indexOf("+"))) : 0
      })
      formated_highlights.sort((a, b) => a.pageIndex - b.pageIndex); //get these in the order from page start to finish
    });



  const newSelectedItem = (area, positionX, positionY) => {
    var windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    var windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    const leftPercentage = (area.left + area.width + 1);

    const finalObj = {
      x: area.left,
      y: area.top,
      title: area.title,
      value: area.text,
      positionX: leftPercentage > 60 ? "calc(" + leftPercentage + "% - 260px)" : (area.left + area.width + 1) + "%",//(positionX+250) > windowWidth/2 ? positionX - 295 : positionX /* > 400 ? 350 : positionX*/,
      positionY: area.top > 60 ? "calc(" + area.top + "% - 300px)" : area.top + "%",//positionY < 420 ? (windowHeight/2) - 100 : (positionY+100+333) > (windowHeight/2) ? ((windowHeight/2)) - 100 : positionY,
      pageIndex: area.pageIndex,
      references: area.references,
      summedItem: area.summedItem,
      averagedItem: area.averagedItem,
      isWrong: area.isWrong,
      absCheck: area.absCheck
    };
    console.log("area: ", area)
    console.log("final y: ", finalObj)
    setSelectedItem(finalObj);
  }

  const resetSelectedItem = () => {
    console.log("hide...")
    const selectedItemx = { ...selectedItem };
    selectedItemx.pageIndex = -1;
    setSelectedItem(selectedItemx);
  }

  const hoverHandler = (area) => {
    const highlightedItemsx = {};
    area.summedItem?.itemsSummed?.forEach((reference, idx) => {
      highlightedItemsx[reference.id] = 1;

    });

    if (area.summedItem) {
      if (area.summedItem.isDifference) {
        setMainHighlightedItemType("Difference");
      } else {
        setMainHighlightedItemType("Total");
      }
      
    }

    area.averagedItem?.itemsAveraged.forEach((reference, idx) => {
      highlightedItemsx[reference.id] = 1;

    });

    if (area.averagedItem) {
      setMainHighlightedItemType("Average");
    }

    if (area.calculationType) {
      setMainHighlightedItemType(area.calculationType);
    }



    sethighlightedItems(highlightedItemsx);
    if (Object.keys(highlightedItemsx).length) {
      setMainHighlightedItem(area);
    } else {
      setMainHighlightedItem(null);
    }



  }

  const openSideBarItem = (area) => {
    // jumpToHighlightArea(area);
    jumpToPage(area.pageIndex);
    hoverHandler(area);

    //document.getElementById(area.id)?.click();
    setTimeout(function () {
      hoverHandler(area);
      //document.getElementById(area.id)?.click();
    }, 3000);

  }

  const getHighlightFormatting = (area) => {
    // Categorize the cell type based on area properties
    const notCalculatedCell = !area.summedItem && !area.averagedItem;
    const calculatedRowCell = area.rowCalcResult;
    const calculatedColumnCell = area.colCalcResult;
  
    // Default styles for non-calculated cells
    let style = {
      background: notCalculatedCell ? 'transparent' : (area.isWrong ? "red" : 'green'),
      borderTop: 'none',
      borderLeft: 'none'
    };
  
    // Customize style for calculated cells
    // if (calculatedRowCell) {
    //   if (area.isWrong) {
    //     style.borderTop = '2px solid red'; 
    //   } else {
    //     style.borderTop = '2px solid green'; 
    //   }
    // }
  
    // if (calculatedColumnCell) {
    //   if (area.isWrong) {
    //     style.borderLeft = '2px solid red'; 
    //   } else {
    //     style.borderLeft = '2px solid green';
    //   }
    // }
  
    return style;
  };
  
  const renderHighlights = (props) => {
    return (
      <>
        <div>
          {formated_highlights
            .filter((area) => area.pageIndex === props.pageIndex && area.text !== "-")
            .map((area, idx) => {
              const highlightFormatting = getHighlightFormatting(area);
  
              return highlightedItem === area.id || highlightedItems[area.id] ? (
                <div
                  key={idx}
                  className="highlight-area"
                  onMouseEnter={() => hoverHandler(area)}
                  onClick={(data) => newSelectedItem(area, data.target.getBoundingClientRect().x, data.target.getBoundingClientRect().y)}
                  style={Object.assign(
                    {},
                    {
                      zIndex: 10000,
                      background: 'orange',
                      opacity: 0.4,
                    },
                    props.getCssProperties(area, props.rotation)
                  )}
                />
              ) : (
                <div
                  key={idx}
                  id={area.id}
                  className="highlight-area hide-area"
                  onMouseEnter={() => hoverHandler(area)}
                  onClick={(data) => newSelectedItem(area, data.target.getBoundingClientRect().x, data.target.getBoundingClientRect().y)}
                  style={Object.assign(
                    {},
                    {
                      zIndex: 10000,
                      opacity: 0.25,
                      background: highlightFormatting.background, 
                      borderTop: highlightFormatting.borderTop,
                      borderLeft: highlightFormatting.borderLeft
                    },
                    props.getCssProperties(area, props.rotation)
                  )}
                />
              );
            })}
        </div>
      </>
    );
  }
  

  const highlightPluginInstance = highlightPlugin({
    renderHighlights,
    trigger: Trigger.None,
  });

  const pageNavigationPluginInstance = pageNavigationPlugin();
  const jumpToPage = pageNavigationPluginInstance.jumpToPage

  // const { jumpToHighlightAreaMain } = highlightPluginInstance.jumpToHighlightArea;

  // const jumpToHighlightArea = (area) => {
  //   if (area) jumpToHighlightAreaMain(area);
  // }

  const jumpToHighlightArea = (area) => {
    if (area && jumpToPage) {
        jumpToPage(area.pageIndex);
    }
}

  const highlightPluginInstance2 = highlightPlugin({
    renderHighlights,
    trigger: Trigger.None,
  });
  const jumpToHighlightArea2 = highlightPluginInstance2.jumpToHighlightArea;

  // const popOverHoverModal = () => (
  //   <>
  //     {mainHighlightedItem ? (
  //       <Paper elevation={3}>
  //         <div className='pdf-pop-over-factual-accuracy stand-alone-popover' style={{ bottom: 0, right: 0, textAlign: "right", paddingLeft: "0", paddingRight: "0" }}>
  //           <p id='pdf-pop-over-title'>{mainHighlightedItemType}</p>
  //           <div>
  //             {mainHighlightedItem.summedItem && mainHighlightedItem.summedItem.itemsSummed.length && mainHighlightedItem.summedItem.toalPercentageIncrease ?
  //               null
  //               :
  //               mainHighlightedItem.summedItem && mainHighlightedItem.summedItem.itemsSummed.length ?
  //                 mainHighlightedItem.summedItem.itemsSummed.map((reference, idx) => (
  //                   <div key={idx}>
  //                     <span className='calc-sign'>{mainHighlightedItem.calculationOperators[idx] || ""}</span>
  //                     <p className='reference-items-factual-accuracy' onClick={() => { /* Event handler code here */ }}>
  //                       <a href='javascript:void(0)'>
  //                         <span></span>
  //                         <span>{reference.text.indexOf("(") !== -1 && reference.text.indexOf(")") !== -1 ? `(${isNaN(Number(cleanNumber(reference.text.replace(")", "").replace("(", "").replace("%", "")))) ? 0 : Number(cleanNumber(reference.text.replace(")", "").replace("(", ""))).toLocaleString()})` : isNaN(Number(cleanNumber(reference.text.replace("%", "")))) ? 0 : Number(cleanNumber(reference.text.replace("%"))).toLocaleString()}{reference.text.indexOf("%") !== -1 ? "%" : ""}</span>
  //                       </a>
  //                     </p>
  //                   </div>
  //                 ))
  //                 :
  //                 mainHighlightedItem.averagedItem && mainHighlightedItem.averagedItem.itemsAveraged.length ?
  //                   mainHighlightedItem.averagedItem.itemsAveraged.map((reference, idx) => (
  //                     <div key={idx}>
  //                       <span className='calc-sign'>{mainHighlightedItem.calculationOperators[idx] || ""}</span>
  //                       <p className='reference-items-factual-accuracy' onClick={() => { /* Event handler code here */ }}>
  //                         <a href='javascript:void(0)'>
  //                           <span></span>
  //                           <span>{reference.text.indexOf("(") !== -1 && reference.text.indexOf(")") !== -1 ? `(${isNaN(Number(cleanNumber(reference.text.replace(")", "").replace("(", "").replace("%", "")))) ? 0 : Number(cleanNumber(reference.text.replace(")", "").replace("(", ""))).toLocaleString()})` : isNaN(Number(cleanNumber(reference.text.replace("%", "")))) ? 0 : Number(cleanNumber(reference.text.replace("%"))).toLocaleString()}{reference.text.indexOf("%") !== -1 ? "%" : ""}</span>
  //                         </a>
  //                       </p>
  //                     </div>
  //                   ))
  //                   :
  //                   <p className='no-reference-text'>No other references found.</p>
  //             }
  
  //             {/* Conditionally rendering Divider if there is a calculated sum or average */}
  //             {((mainHighlightedItem.summedItem && mainHighlightedItem.summedItem.summedTotal !== undefined) ||
  //               (mainHighlightedItem.averagedItem && mainHighlightedItem.averagedItem.averaged !== undefined)) && <Divider />}
  
  //             <p className='calculated-expected-difference'><span style={{ float: "left" }}>△</span><span>{Number((Number(removeSymbolsFromNumbers(cleanNumber(mainHighlightedItem.calculatedNumber))) - Number(removeSymbolsFromNumbers(cleanNumber(mainHighlightedItem.text))).toFixed(2))).toLocaleString()}</span></p>
  
  //             <Divider /> {/* Always rendering Divider before expected results */}
  //             <div className='expected-result'><span style={{ opacity: 0.7 }}></span><span>{isNaN(Number(mainHighlightedItem.text)) ? mainHighlightedItem.text : Number(mainHighlightedItem.text).toLocaleString()}</span></div>
  //           </div>
  //         </div>
  //       </Paper>
  //     ) : null}
  //   </>
  // );

  const resetMainHighlightedItem = () => {
    setMainHighlightedItem(null);

  }

  const setRenderRange1: SetRenderRange = React.useCallback((visiblePagesRange: VisiblePagesRange) => {
    const startPage = 0; //Math.max(0, visiblePagesRange.startPage - 10);
    const endPage = visiblePagesRange.numPages; //visiblePagesRange.endPage + 10;

    console.log("setRenderRange1 - Visible Pages Range:", visiblePagesRange);
    console.log("setRenderRange1 - Calculated startPage:", startPage);
    console.log("setRenderRange1 - Calculated endPage:", endPage);

    return {
      startPage: startPage,
      endPage: endPage,
    };
  }, []);

  const popOverHoverModal = () => (
    <>
      {
        mainHighlightedItem ?
          <>
            {/* <MathJaxContext> */}
            <ClickAwayListener onClickAway={() => resetMainHighlightedItem()}>
              <div className='pdf-pop-over-factual-accuracy stand-alone-popover' style={{ marginRight: "2em", marginBottom: "1em", bottom: 0, right: 0, textAlign: "right", textAlign: "right", paddingLeft: "0", paddingRight: "0" }}>
              <Paper elevation={5} sx={{pt: 1, pb: 1}} >
                <p id='pdf-pop-over-title'>{mainHighlightedItemType}</p>

                <div>
                  {
                    (mainHighlightedItem.summedItem && mainHighlightedItem.summedItem.itemsSummed.length && mainHighlightedItem.summedItem.toalPercentageIncrease)
                      || (mainHighlightedItem.averagedItem && mainHighlightedItem.averagedItem.itemsAveraged.length && mainHighlightedItem.averagedItem.cagr) ?
                      null
                      :
                      mainHighlightedItem.summedItem && mainHighlightedItem.summedItem.itemsSummed.length ?

                        mainHighlightedItem.summedItem.itemsSummed.map((reference, idx) => (//formated_highlights
                          <div><span className='calc-sign'>{mainHighlightedItem.calculationOperators[idx] ? mainHighlightedItem.calculationOperators[idx] : ""}</span><p className='reference-items-factual-accuracy' key={idx} onClick={() => { console.log("clicked: ", reference, formated_highlights.find(highlightitem => highlightitem.id === reference.id)); sethighlightedItem(reference.id); jumpToHighlightArea(formated_highlights.find(highlightitem => highlightitem.id === reference.id)); console.log({ lol: formated_highlights.find(highlightitem => highlightitem.id === reference.id), reference, references: mainHighlightedItem.references, idx }) }}><a href='javascript:void(0)'><span></span><span>{reference.text.indexOf("(") !== -1 && reference.text.indexOf(")") !== -1 ? `(${isNaN(Number(cleanNumber(reference.text.replace(")", "").replace("(", "").replace("%", "")))) ? 0 : Number(cleanNumber(reference.text.replace(")", "").replace("(", ""))).toLocaleString()})` : isNaN(Number(cleanNumber(reference.text.replace("%", "")))) ? 0 : reference.originalText}</span></a></p></div>
                        ))
                        :
                        mainHighlightedItem.averagedItem && mainHighlightedItem.averagedItem.itemsAveraged.length ?

                          mainHighlightedItem.averagedItem.itemsAveraged.map((reference, idx) => (//formated_highlights
                            <div><span className='calc-sign'>{mainHighlightedItem.calculationOperators[idx] ? mainHighlightedItem.calculationOperators[idx] : ""}</span><p className='reference-items-factual-accuracy' key={idx} onClick={() => { console.log("clicked: ", reference, formated_highlights.find(highlightitem => highlightitem.id === reference.id)); sethighlightedItem(reference.id); jumpToHighlightArea(formated_highlights.find(highlightitem => highlightitem.id === reference.id)); console.log({ lol: formated_highlights.find(highlightitem => highlightitem.id === reference.id), reference, references: mainHighlightedItem.references, idx }) }}><a href='javascript:void(0)'><span></span><span>{reference.text.indexOf("(") !== -1 && reference.text.indexOf(")") !== -1 ? `(${isNaN(Number(cleanNumber(reference.text.replace(")", "").replace("(", "").replace("%", "")))) ? 0 : Number(cleanNumber(reference.text.replace(")", "").replace("(", ""))).toLocaleString()})` : isNaN(Number(cleanNumber(reference.text.replace("%", "")))) ? 0 : Number(cleanNumber(reference.text.replace("%"))).toLocaleString()}{reference.text.indexOf("%") !== -1 ? "%" : ""}</span></a></p></div>
                          ))
                          :
                          <p className='no-reference-text'>No other references found.</p>
                  }
                  {
                    mainHighlightedItem.averagedItem && mainHighlightedItem.averagedItem.itemsAveraged.length && mainHighlightedItem.averagedItem.cagr !== undefined ?
                      <span style={{ color: mainHighlightedItem.isWrong ? "red" : "green" }}> <MathJax>{"\\((\\frac{" + Number(mainHighlightedItem.averagedItem.numOfItems[1].text).toLocaleString() + "}{" + Number(mainHighlightedItem.averagedItem.numOfItems[0].text).toLocaleString() + "})^\\frac{1}{5} \\approx " + (Math.round(Number(mainHighlightedItem.averagedItem.cagr) * 100) / 100) + "\\)"}</MathJax></span>
                      /*<span className='calculated-sum'><span style={{ float: "left" }}>{mainHighlightedItemType.toLowerCase() === "total" || mainHighlightedItemType.toLowerCase() === "average"  ? "∑" : ""}</span><span>{Math.round(Number(mainHighlightedItem.averagedItem.summedTotal) * 100) / 100}</span></span>*/
                      :
                      mainHighlightedItem.summedItem && mainHighlightedItem.summedItem.itemsSummed.length && mainHighlightedItem.summedItem.toalPercentageIncrease !== undefined ?
                        <span style={{ color: mainHighlightedItem.isWrong ? "red" : "green" }}> <MathJax>{"\\(\\frac{" + Number(mainHighlightedItem.summedItem.numOfItems[1].text).toLocaleString() + "-" + Number(mainHighlightedItem.summedItem.numOfItems[0].text).toLocaleString() + "}{" + Number(mainHighlightedItem.summedItem.numOfItems[0].text).toLocaleString() + "} * 100 \\approx " + (Math.round(Number(mainHighlightedItem.summedItem.toalPercentageIncrease) * 100) / 100) + "\\)"}</MathJax></span>
                        :
                        mainHighlightedItem.summedItem && mainHighlightedItem.summedItem.itemsSummed.length && mainHighlightedItem.summedItem.summedTotal !== undefined ?
                          <span className='calculated-sum'><span style={{ float: "left" }}>{mainHighlightedItemType.toLowerCase() === "total" || mainHighlightedItemType.toLowerCase() === "average" ? "∑" : ""}</span><span>{formatFinalResult(Math.round(Number(mainHighlightedItem.calculatedNumber) * 100) / 100, mainHighlightedItem.text)}</span></span>
                          :
                          mainHighlightedItem.averagedItem && mainHighlightedItem.averagedItem.itemsAveraged.length && mainHighlightedItem.averagedItem.averaged !== undefined ?
                            /*<span style={{ color: mainHighlightedItem.isWrong ? "red" : "green" }}> = {mainHighlightedItem.averagedItem.summedTotal} <MathJax>{"\\(\\frac{" + mainHighlightedItem.averagedItem.summedTotal + "}{" + mainHighlightedItem.averagedItem.itemsAveraged.length + "} \\approx " + (Math.round(Number(mainHighlightedItem.averagedItem.averaged) * 100) / 100) + "\\)"}</MathJax></span>*/
                            <>
                              <span className='calculated-sum' style={{ fontWeight: "normal" }}><span style={{ float: "left" }}>{mainHighlightedItemType.toLowerCase() === "total" || mainHighlightedItemType.toLowerCase() === "average" ? "∑" : ""}</span><span>{formatFinalResult(Math.round(Number(mainHighlightedItem.averagedItem.summedTotal) * 100) / 100, mainHighlightedItem.text)}</span></span>
                              <div><span className='calc-sign'>/</span><p className='reference-items-factual-accuracy'><a href='javascript:void(0)'><span></span><span>{mainHighlightedItem.averagedItem.itemsAveraged.length}</span></a></p></div>
                              <span className='calculated-sum'><span style={{ float: "left" }}>{mainHighlightedItemType.toLowerCase() === "total" || mainHighlightedItemType.toLowerCase() === "average" ? "x̄" : ""}</span><span>{Number(formatFinalResult(Math.round(mainHighlightedItem.averagedItem.averaged) * 100) / 100, mainHighlightedItem.text)}</span></span>
                            </>

                            :
                            null
                  }
                  {
                    <p style={{ color: mainHighlightedItem.isWrong ? "red" : "green" }} className='calculated-expected-difference'><span style={{ float: "left" }}>△</span><span>{Number((Number(mainHighlightedItem.calculatedNumber) - Number(removeSymbolsFromNumbers(cleanNumber(mainHighlightedItem.originalText))).toFixed(2))).toLocaleString().replace("-0","0")}</span></p>
                  }
                  <div className='expected-result'><span style={{ opacity: 0.7 }}></span><span>{ handleCommasInNumbers(mainHighlightedItem.originalText)}</span></div>


                  {/*<MKButton color="light">+ Add manual refference</MKButton>*/}
                </div>
                </Paper>
              </div>
            </ClickAwayListener>
            {/* </MathJaxContext> */}

          </>
          :
          null
      }
    </>

  )

  const popOverModalNOTINUSE = (props) => (
    <>
      {
        props.pageIndex === selectedItem.pageIndex ?
          <>
            <div className='pop-over-bg' onClick={() => resetSelectedItem()}></div>
            <MathJaxContext>
              <div className='pdf-pop-over-factual-accuracy' style={{ top: selectedItem.positionY/*-200*/, left: selectedItem.positionX }} onClick={() => console.log("props: ", props)}>
                <p id='pdf-pop-over-title'>{selectedItem.title} <IoMdClose className='close-pop-up-buttom' onClick={() => resetSelectedItem()} /></p>
                <div><span style={{ opacity: 0.7 }}>Value: </span><span style={{ float: "right", color: "green" }}>{selectedItem.value}</span></div>
                <div>
                  <h5>References</h5>
                  {
                    /*
                    (selectedItem.summedItem && selectedItem.summedItem.itemsSummed.length && selectedItem.summedItem.toalPercentageIncrease)
                    || (selectedItem.averagedItem && selectedItem.averagedItem.itemsAveraged.length && selectedItem.averagedItem.cagr) ?
                    null
                    :
                    selectedItem.summedItem && selectedItem.summedItem.itemsSummed.length ?
                    
                    selectedItem.summedItem.itemsSummed.map((reference, idx) => (//formated_highlights
                      <span className='reference-items-factual-accuracy' key={idx} onClick={() => { console.log("clicked: ",reference, formated_highlights.find(highlightitem => highlightitem.id === reference.id)); sethighlightedItem(reference.id); jumpToHighlightArea(formated_highlights.find(highlightitem => highlightitem.id === reference.id)); console.log({lol: formated_highlights.find(highlightitem => highlightitem.id === reference.id), reference, references: selectedItem.references, idx})}}>{idx > 0 ? " + " : ""} <a href='javascript:void(0)'><span></span><span>{reference.text}</span></a></span>
                    ))
                    :
                    selectedItem.averagedItem && selectedItem.averagedItem.itemsAveraged.length ?
                    
                    selectedItem.averagedItem.itemsAveraged.map((reference, idx) => (//formated_highlights
                      <span className='reference-items-factual-accuracy' key={idx} onClick={() => { console.log("clicked: ",reference, formated_highlights.find(highlightitem => highlightitem.id === reference.id)); sethighlightedItem(reference.id); jumpToHighlightArea(formated_highlights.find(highlightitem => highlightitem.id === reference.id)); console.log({lol: formated_highlights.find(highlightitem => highlightitem.id === reference.id), reference, references: selectedItem.references, idx})}}>{idx > 0 ? " + " : ""} <a href='javascript:void(0)'><span></span><span>{reference.text}</span></a></span>
                    ))
                    :
                    <p className='no-reference-text'>No other references found.</p>
                    */
                  }
                  {
                    selectedItem.averagedItem && selectedItem.averagedItem.itemsAveraged.length && selectedItem.averagedItem.cagr ?
                      <span style={{ color: selectedItem.isWrong ? "red" : "green" }}> <MathJax>{"\\((\\frac{" + Number(selectedItem.averagedItem.numOfItems[1].text).toLocaleString() + "}{" + Number(selectedItem.averagedItem.numOfItems[0].text).toLocaleString() + "})^\\frac{1}{5} \\approx " + (Math.round(Number(selectedItem.averagedItem.cagr) * 100) / 100) + "\\)"}</MathJax></span>
                      :
                      selectedItem.summedItem && selectedItem.summedItem.itemsSummed.length && selectedItem.summedItem.toalPercentageIncrease ?
                        <span style={{ color: selectedItem.isWrong ? "red" : "green" }}> <MathJax>{"\\(\\frac{" + Number(selectedItem.summedItem.numOfItems[1].text).toLocaleString() + "-" + Number(selectedItem.summedItem.numOfItems[0].text).toLocaleString() + "}{" + Number(selectedItem.summedItem.numOfItems[0].text).toLocaleString() + "} * 100 \\approx " + (Math.round(Number(selectedItem.summedItem.toalPercentageIncrease) * 100) / 100) + "\\)"}</MathJax></span>
                        :
                        selectedItem.summedItem && selectedItem.summedItem.itemsSummed.length && selectedItem.summedItem.summedTotal ?
                          <span style={{ color: selectedItem.isWrong ? "red" : "green" }}> = {Math.round(Number(selectedItem.summedItem.summedTotal) * 100) / 100}</span>
                          :
                          selectedItem.averagedItem && selectedItem.averagedItem.itemsAveraged.length && selectedItem.averagedItem.averaged ?
                            <span style={{ color: selectedItem.isWrong ? "red" : "green" }}> = {selectedItem.averagedItem.summedTotal} <MathJax>{"\\(\\frac{" + selectedItem.averagedItem.summedTotal + "}{" + selectedItem.averagedItem.itemsAveraged.length + "} \\approx " + (Math.round(Number(selectedItem.averagedItem.averaged) * 100) / 100) + "\\)"}</MathJax></span>
                            :
                            null
                  }


                  {/*<MKButton color="light">+ Add manual refference</MKButton>*/}
                </div>
                <div>
                  <Grid item xs={12}>
                    <MKInput variant="standard" label="Comment" multiline fullWidth rows={6} />
                  </Grid>
                </div>
              </div>
            </MathJaxContext>

          </>
          :
          null
      }
    </>

  )


  const itemTitles = {};
  const processedTitles = {};

  const itemPages = {};
  const processedPages = {};

  formated_highlights.filter(item => item.isWrong && item.calculatedNumber !== null).forEach(item => {
    if (item.tableTitle && !processedTitles[item.tableTitle]) {
      processedTitles[item.tableTitle] = 1;
      itemTitles[item.id] = item.tableTitle;
    }

    if (!itemPages[item.pageIndex + 1]) {
      itemPages[item.pageIndex + 1] = 1;
      processedPages[item.id] = item.pageIndex + 1;
    }
  });

  const SidebarErrorMenuItem = ({ highlight, handleClick }) => {

    return (
      <Stack key={highlight.id} spacing={0.5} sx={{ width: '100%', cursor: 'pointer', '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.03)' } }} onClick={handleClick}>
        <Grid container spacing={1} alignItems="center" sx={{ width: '100%' }}>
          <Grid item xs={1.5}>
            <PriorityHighIcon />
          </Grid>
          <Grid item xs={8.5} noWrap>
            <Typography variant="body2" sx={{ mb: -0.5 }} noWrap>
              {highlight.originalText}
            </Typography>
            <Typography variant="caption" sx={{ color: "rgba(0,0,0,0.8)", marginRight: "4px" }}>
              Expected value: {highlight.calculatedNumber.toLocaleString()}
              {/* {formatFinalResult(highlight.rowCalcResult, highlight.originalText)} */}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body3" textAlign="center" sx={{ color: 'rgba(0,0,0,0.4)' }} >
              p.{highlight.page_num}
            </Typography>
          </Grid>
        </Grid>
        <Divider variant="middle" />
      </Stack>
    );
  };

  // const commentMenuId="f74418e2-f80d-47c9-ae1b-be15a44ef419"

  const sidebarList = () => {
    return (
      <div style={{ overflow: 'auto', width: '100%' }}>
        <Stack spacing={0} sx={{ pt: 0 }}>
          {formated_highlights.filter(item => item.isWrong && item.calculatedNumber !== null).length > 0 ? (
            formated_highlights.filter(item => item.isWrong && item.calculatedNumber !== null).map((highlight, index) => 
            // formated_highlights.filter(item => item.id === commentMenuId).map((highlight, index) => 
            (
              <SidebarErrorMenuItem
                key={index}
                highlight={highlight}
                handleClick={() => {
                  console.log("Highlight item selected:", highlight);
                  // Assuming setSelectedItemHandler is a function to handle selection
                  openSideBarItem(highlight);
                }}
              />
            ))
          ) : (
            <Stack sx={{ width: '100%' }}>
              <Typography variant="body1" color="rgba(0,0,0,0.6)" sx={{ mt: 2, textAlign: 'center' }}>
                No alert items found
              </Typography>
            </Stack>
          )}
        </Stack>
      </div>
    );
  };
  


  return (
    <PanelGroup autoSaveId="factualAccuracy" direction="horizontal" style={{ height: "calc(100vh/var(--zoom-level) - 145px)" }}>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
        <Card
          sx={{
            p: 1,
            mr: { xs: 1, lg: 2 },
            ml: 0,
            mt: 0,
            mb: 0,
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
          style={{ width: "22.5em" }}
        >
          <Typography variant="body1" fontWeight="medium" align="center" sx={{ pt: "12px", pb: "6px" }} >
          Review summary
          </Typography>
          <Divider sx={{ my: 1 }} />
          {sidebarList()}
          {/* <Grid overflow={"scroll"} paddingRight={"7px"} item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>

            <Stack>
              {
                !formated_highlights.filter(item => item.isWrong && item.calculatedNumber !== null).length ?
                  <p className='no-errors-found'>There were no potential errors found.</p>
                  :
                  formated_highlights.filter(item => item.isWrong && item.calculatedNumber !== null).map((area, idx) => (
                    <>
                      {
                        processedPages[area.id] ?
                          <div style={{ width: "100%", height: "30px" }}>
                            <MKBadge badgeContent={<><SiPowerpages style={{ marginRight: "5px" }} /> <span>Page {processedPages[area.id]}</span></>} color="dark" container style={{ float: "right" }} />
                          </div>
                          :
                          null
                      }
                      {
                        itemTitles[area.id] ?
                          <div title={itemTitles[area.id]}>
                            <MKTypography variant="h6" fontWeight="bold" style={{ marginBottom: "10px" }}>
                              <span><MdTableChart /></span> <span className='page-section-title'>{itemTitles[area.id]}</span>
                            </MKTypography>
                          </div>
                          :
                          null
                      }


                      <a href="javascript:void(0)" className='sidebar-item' onClick={() => { openSideBarItem(area) }}>
                        <MKBox display="flex" alignItems="center" p={2}>
                          <MKBox
                            height="3rem"
                            variant="gradient"
                            bgColor="secondary"
                            color="white"
                            coloredShadow="secondary"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            borderRadius="xl"
                            minWidth="90px"
                          >
                            <span style={{ paddingLeft: "5px", paddingRight: "5px" }}>{area.text.indexOf("%") !== -1 ? area.text : Number(extractFinancialNumbers(cleanNumber(area.text)).length ? extractFinancialNumbers(cleanNumber(area.text))[0].replace("$","") : "0").toLocaleString()}</span>
                          </MKBox>
                          <MKTypography style={{ textTransform: "capitalize" }} variant="body2" color="text" pl={2}>
                            {area.contextB} - {area.contextC}
                            <br />
                            Expected: <b>{Number(area.calculatedNumber).toLocaleString()}{area.text.indexOf("%") !== -1 ? "%" : ""}</b>
                          </MKTypography>
                        </MKBox>
                      </a>
                    </>
                  ))
              }



            </Stack>
          </Grid> */}
        </Card>
        {
          popOverHoverModal()
        }
        <Viewer fileUrl={firstPdf}
        setRenderRange={setRenderRange1}
          onDocumentLoad={() => {
            //jumpToPage1(62); setTimeout(() => jumpToPage1(1), 100)
            setTimeout(function () {
              document.querySelector(".loaded-main-component").style.opacity = "1"
            }, 280);
          }
          }
          defaultScale={SpecialZoomLevel.PageFit}
          pageLayout={pageLayout}
          plugins={[defaultLayoutPluginInstance, highlightPluginInstance, pageNavigationPluginInstance]} />

        <FeedbackFab />

      </Worker>
    </PanelGroup>

  )
}

export default FactualAccuracy;