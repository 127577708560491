import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
//import posthog from 'posthog-js'

const domain = window.location.hostname;

function generateSessionId() {
  const timestamp = Date.now().toString(36); // Convert current timestamp to base-36 string
  const randomNum = Math.random().toString(36).substring(2); // Generate a random base-36 string
  return `${timestamp}-${randomNum}`;
}

const sessionId = generateSessionId();

console.log({sessionId})
/*
if (process.env.NODE_ENV === 'production' && (window as any).profileStatus && (window as any).profileStatus.email && domain !== "dev.zencheck.ai") {
  console.log("tr a")
  posthog.init('phc_q7zOxELmWtcCgNeH8h1dFOHWtuvHYIIppPNH14OCmXO',
    {
        api_host: 'https://us.i.posthog.com',
        person_profiles: 'always', // or 'always' to create profiles for anonymous users as well
        autocapture: true,
        session_recording: {
          maskAllInputs: false,
        },
        bootstrap: {
          sessionID: sessionId
        }
        
    }
  )

if ((window as any).profileStatus.email) {
  console.log("tr b")
  const userId = (window as any).profileStatus.email;
  if (userId) {
    console.log("tr c",posthog)
    posthog.identify(userId);

    posthog.people.set({
      email: userId
    });
  }
  

  
}
}

*/
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
