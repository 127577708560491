import React, { useState } from 'react';
import { Fab as Fabx, Popper, IconButton, Tooltip, Paper, Typography, TextField, Box, InputAdornment, Fade } from '@mui/material';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';

const FeedbackFab = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [message, setMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [tryingToSend, setTryingToSend] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("Feedback sent");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubmit = () => {
    console.log('Feedback submitted:', message);
    setTryingToSend(true);
    const domain = window.location.hostname;
    if (window.profileStatus && window.profileStatus.csrfToken) {
      const csrfToken = window.profileStatus.csrfToken;

      axios.post(domain === 'localhost' ? 'https://dev.zencheck.ai/alphax/send-feedback' : '/alphax/send-feedback', {message}, { headers: {
        'X-CSRF-Token': csrfToken
      },
      withCredentials:domain === 'localhost' ? false : true})
      .then((response) => {
        if (response.data.ok) {
          setNotificationMessage("Feedback sent");
          setSubmitted(true);
          setMessage('');
          handleClose();

        } else {
          setNotificationMessage("An error occured whilst sending your feedback.");
        }
        setTryingToSend(false);
      })
      .catch((error) => {
        console.log(error);
        setNotificationMessage("An error occured whilst sending your feedback.");
        setTryingToSend(false);

      });
    }
    
      
  };

  const handleCloseEvent = (event, reason) => {
    setSubmitted(false)
  }

  const handleInputChange = (event) => {
    setMessage(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      if (message.trim()) {
        handleSubmit();
      }
    } else if (event.key === 'Escape') {
      handleClose();
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  return (
    <>
    <Snackbar
  open={submitted}
  autoHideDuration={2000}
  onClose={handleCloseEvent}
  message={notificationMessage}
  action={() => {}}
/>
      <Tooltip title="Give feedback" placement="left">
        <Fabx 
          color="default" 
          aria-label="give feedback"
          size="small" 
          sx={{
            position: 'fixed',
            bottom: 16,
            right: 16,
          }}
          onClick={handleClick}
        >
          <FeedbackOutlinedIcon />
        </Fabx>
      </Tooltip>

      <Popper 
        id={id} 
        open={open} 
        anchorEl={anchorEl} 
        placement="top-end" 
        transition 
        modifiers={[{ name: 'offset', options: { offset: [0, 8] } }]}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={100}>
            <Paper elevation={3} sx={{ padding: 1, width: 300 }}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h6">Feedback</Typography>
                <Tooltip title="Close" placement="top">
                  <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    size="small"
                    sx={{
                      position: 'relative',
                      right: 0,
                      top: 0,
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              <TextField
                autoFocus
                id="feedback"
                type="text"
                fullWidth
                variant="outlined"
                disabled={tryingToSend}
                value={message}
                onChange={handleInputChange}
                onKeyDown={handleKeyPress}
                multiline
                maxRows={12}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={{ pr: 1 }}>
                      <Tooltip title="Submit" placement="bottom">
                        <IconButton
                          aria-label="submit feedback"
                          onClick={handleSubmit}
                          disabled={!message.trim()}
                          size="small"
                        >
                          <SendIcon size="small" />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                sx={{ mt: 1 }}
              />
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
};

export default FeedbackFab;
